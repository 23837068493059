import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import './assets/tailwind.css';
import './assets/base.css';

import VueTagManager from "vue-tag-manager"
 
Vue.use(VueTagManager, {
    gtmId: 'GTM-MPTX7NS'
})


Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');
