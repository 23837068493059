import XLSX from "xlsx";

export const getFile = (event: any) => {
  if (!event) {
    throw "Failed to get event.";
  }

  const files = event.target.files;
  if (!files || files.length == 0) return;
  return files[0];
};

export const parseBinaryToJSON = (binary: any, options: any) => {
  /* read workbook */
  const wb = XLSX.read(binary, { type: "binary" });

  /* grab first sheet */
  const wsname =
    wb.SheetNames[options && options.sheetNumber ? options.sheetNumber : 0];
  const ws = wb.Sheets[wsname];
  /* generate HTML */
  const json = XLSX.utils.sheet_to_json(ws, {
    range: options ? options.range : undefined
  });

  return json;
};
