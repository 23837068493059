























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { PROCESSING_STATE } from "@/types";

@Component
export default class FileRow extends Vue {
  @Prop() text;
  @Prop() id;
  @Prop() state;
  @Prop() handler;

  get acceptedFormats() {
    return ["xlsx", "xls"].map(ext => `.${ext}`).join(",");
  }

  get buttonMessage() {
    if (this.isLoading) return "Loading...";
    if (this.isProcessing) return "Processing...";
    if (this.isDone) return "Processed";
    if (this.isLoaded) return "File Loaded";
    return "Choose a file";
  }

  get isLoaded() {
    return this.state === PROCESSING_STATE.LOADED;
  }

  get isLoading() {
    return this.state === PROCESSING_STATE.LOADING;
  }

  get isProcessing() {
    return this.state === PROCESSING_STATE.PROCESSING;
  }

  get isDone() {
    return this.state === PROCESSING_STATE.DONE;
  }

  @Watch('state')
  onStateChange(newVal) {
    if (newVal === PROCESSING_STATE.NOT_LOADED) {
      (this.$refs.fileInput as HTMLInputElement).value = ""
    }
  }
}
