import ReaderWorker from "worker-loader!../workers/Reader";
import ParserWorker from "worker-loader!../workers/Parser";
import TransformWorker from "worker-loader!../workers/Transform";
import XLSWorker from "worker-loader!../workers/XLS";
import XLSX from "xlsx";

export const readFileFromWorker = (file: any, cb?: any) =>
  new Promise((res, rej) => {
    const reader = new ReaderWorker();
    reader.postMessage({ file });
    reader.onmessage = (event: any) => {
      const { done, progress, result } = event.data;
      if (cb) {
        cb(progress);
      }
      if (done) {
        res(result);
        console.log("Terminating File Worker");
        reader.terminate();
      }
    };
    reader.onerror = (event: any) => {
      rej("Failed to read file.");
      reader.terminate();
    };
  });

export const parseBinaryToJsonInWorker = (binary: any, options: any) =>
  new Promise((res, rej) => {
    const parser = new ParserWorker();
    parser.postMessage({ binary, options });
    parser.onmessage = (event: any) => {
      const data = event.data;
      res(data);
      console.log("Terminating Parser Worker");
      parser.terminate();
    };
    parser.onerror = (event: any) => {
      rej("Failed to parse binary.");
      parser.terminate();
    };
  });

export const mergeSheetsInWorker = (sheetData: any) =>
  new Promise((res, rej) => {
    const transformer = new TransformWorker();
    transformer.postMessage(sheetData);
    transformer.onmessage = (event: any) => {
      const data = event.data;
      res(data);
      console.log("Terminating Merge Worker");
      transformer.terminate();
    };
    transformer.onerror = (event: any) => {
      rej("Failed to merge sheet data.");
      transformer.terminate();
    };
  });

export const createXlsInWorker = (json: any) =>
  new Promise((res, rej) => {
    const xlsWorker = new XLSWorker();
    xlsWorker.postMessage(json);
    xlsWorker.onmessage = (event: any) => {
      const data = event.data;
      res(data);
      console.log("Terminating XLS Worker");
      xlsWorker.terminate();
    };
    xlsWorker.onerror = (event: any) => {
      rej("Failed to create XLS file.");
      xlsWorker.terminate();
    };
  });

export const mergeDataToXlsx = async (
  json: any,
  fileName: string = "gds-report"
) => {
  const wb: any = await createXlsInWorker(json);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};
