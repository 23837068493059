








































































import { Component, Vue } from "vue-property-decorator";
import { getFile } from "@/services/reader.ts";
import {
  readFileFromWorker,
  parseBinaryToJsonInWorker,
  mergeSheetsInWorker,
  mergeDataToXlsx
} from "@/services/workers.ts";
import { SheetData } from "@/services/transformer.ts";
import FileRow from "@/components/FileRow.vue";
import { PROCESSING_STATE } from "@/types";
import * as marky from "marky";

enum WORKFLOW {
  NOT_STARTED,
  PROCESSING_FILES,
  MERGING_DATA,
  PREPARING_XLSX,
  COMPLETE
}

@Component({
  components: {
    FileRow
  }
})
export default class App extends Vue {
  hotelRateAuditProgress = PROCESSING_STATE.NOT_LOADED;
  priceSeasonTemplateProgress = PROCESSING_STATE.NOT_LOADED;
  rateTemplateUpdateProgress = PROCESSING_STATE.NOT_LOADED;
  stayRestrictionsProgress = PROCESSING_STATE.NOT_LOADED;
  workflowStage = WORKFLOW.NOT_STARTED;
  fileName = "gds-report";

  created() {
    (this as any).sheetData = {
      hotelRateAuditBinary: null,
      priceSeasonTemplateBinary: null,
      rateTemplateUpdateBinary: null,
      stayRestrictionsBinary: null
    };
  }

  get readyToProcess() {
    return (
      this.hotelRateAuditProgress === PROCESSING_STATE.LOADED &&
      this.priceSeasonTemplateProgress === PROCESSING_STATE.LOADED &&
      this.rateTemplateUpdateProgress === PROCESSING_STATE.LOADED &&
      this.stayRestrictionsProgress === PROCESSING_STATE.LOADED
    );
  }

  get buttonMessage() {
    if (this.workflowStage === WORKFLOW.PROCESSING_FILES)
      return "Processing files...";
    if (this.workflowStage === WORKFLOW.MERGING_DATA) return "Merging data...";
    if (this.workflowStage === WORKFLOW.PREPARING_XLSX)
      return "Preparing XLSX for download...";
    if (this.workflowStage === WORKFLOW.COMPLETE) return "Process Complete";
    return "Start Process";
  }

  get processComplete() {
    return this.workflowStage === WORKFLOW.COMPLETE;
  }

  reset() {
    this.hotelRateAuditProgress = PROCESSING_STATE.NOT_LOADED;
    this.priceSeasonTemplateProgress = PROCESSING_STATE.NOT_LOADED;
    this.rateTemplateUpdateProgress = PROCESSING_STATE.NOT_LOADED;
    this.stayRestrictionsProgress = PROCESSING_STATE.NOT_LOADED;
    this.workflowStage = WORKFLOW.NOT_STARTED;
    this.fileName = "gds-report";
  }

  async loadHotelRateAudit(evt: any) {
    marky.mark("loadFile");
    const file = getFile(evt);
    if (!file) return;
    const payload = {
      file,
      options: { range: 6 }
    };
    this.hotelRateAuditProgress = PROCESSING_STATE.LOADING;
    const data = await readFileFromWorker(file);
    this.hotelRateAuditProgress = PROCESSING_STATE.LOADED;
    (this as any).sheetData.hotelRateAuditBinary = data;
    const time = marky.stop("loadFile");

    // Track
    (this as any).$gtm.push({
      event: "load-file",
      "loaded-file-type": "Hotel Rate Audit",
      timing: time.duration
    });
  }

  async loadPriceSeasonTemplate(evt: any) {
    marky.mark("loadFile");
    const file = getFile(evt);
    if (!file) return;
    const payload = {
      file,
      options: { sheetNumber: 1 }
    };

    this.priceSeasonTemplateProgress = PROCESSING_STATE.LOADING;
    const data = await readFileFromWorker(file);
    this.priceSeasonTemplateProgress = PROCESSING_STATE.LOADED;
    (this as any).sheetData.priceSeasonTemplateBinary = data;
    const time = marky.stop("loadFile");

    // Track
    (this as any).$gtm.push({
      event: "load-file",
      "loaded-file-type": "Price Season Template",
      timing: time.duration
    });
  }

  async loadRateTemplateUpdate(evt: any) {
    marky.mark("loadFile");
    const file = getFile(evt);
    if (!file) return;
    const payload = {
      file
    };

    this.rateTemplateUpdateProgress = PROCESSING_STATE.LOADING;
    const data = await readFileFromWorker(file);
    this.rateTemplateUpdateProgress = PROCESSING_STATE.LOADED;
    (this as any).sheetData.rateTemplateUpdateBinary = data;
    const time = marky.stop("loadFile");

    // Track
    (this as any).$gtm.push({
      event: "load-file",
      "loaded-file-type": "Rate Template Update",
      timing: time.duration
    });
  }

  async loadStayRestriction(evt: any) {
    marky.mark("loadFile");
    const file = getFile(evt);
    if (!file) return;
    const payload = {
      file
    };

    this.stayRestrictionsProgress = PROCESSING_STATE.LOADING;
    const data = await readFileFromWorker(file);
    this.stayRestrictionsProgress = PROCESSING_STATE.LOADED;
    (this as any).sheetData.stayRestrictionsBinary = data;
    const time = marky.stop("loadFile");

    // Track
    (this as any).$gtm.push({
      event: "load-file",
      "loaded-file-type": "Stay Restrictions",
      timing: time.duration
    });
  }

  async downloadData() {
    if (!this.readyToProcess) {
      alert("Load all files first!");
      throw "All required files not loaded";
    }

    marky.mark("processFiles");

    this.workflowStage = WORKFLOW.PROCESSING_FILES;

    // Start Processing
    this.hotelRateAuditProgress = PROCESSING_STATE.PROCESSING;
    this.priceSeasonTemplateProgress = PROCESSING_STATE.PROCESSING;
    this.rateTemplateUpdateProgress = PROCESSING_STATE.PROCESSING;
    this.stayRestrictionsProgress = PROCESSING_STATE.PROCESSING;

    marky.mark("parseFiles");

    const p1 = parseBinaryToJsonInWorker(
      (this as any).sheetData.hotelRateAuditBinary,
      { range: 6 }
    ).then(data => {
      this.hotelRateAuditProgress = PROCESSING_STATE.DONE;
      return data;
    });
    const p2 = parseBinaryToJsonInWorker(
      (this as any).sheetData.priceSeasonTemplateBinary,
      { sheetNumber: 1 }
    ).then(data => {
      this.priceSeasonTemplateProgress = PROCESSING_STATE.DONE;
      return data;
    });
    const p3 = parseBinaryToJsonInWorker(
      (this as any).sheetData.rateTemplateUpdateBinary,
      {}
    ).then(data => {
      this.rateTemplateUpdateProgress = PROCESSING_STATE.DONE;
      return data;
    });
    const p4 = parseBinaryToJsonInWorker(
      (this as any).sheetData.stayRestrictionsBinary,
      {}
    ).then(data => {
      this.stayRestrictionsProgress = PROCESSING_STATE.DONE;
      return data;
    });

    const [
      hotelRateAuditData,
      priceSeasonTemplateData,
      rateTemplateUpdateData,
      stayRestrictionData
    ] = await Promise.all([p1, p2, p3, p4]);

    const sheetData: SheetData = {
      hotelRateAuditData,
      priceSeasonTemplateData,
      rateTemplateUpdateData,
      stayRestrictionData
    };

    // Timing
    const parseTiming = marky.stop("parseFiles");
    (this as any).$gtm.push({
      event: "track-time",
      timing: parseTiming.duration,
      category: "Generate Report",
      label: "Parse Files"
    });

    this.workflowStage = WORKFLOW.MERGING_DATA;
    marky.mark("mergeFiles");

    const json: any = await mergeSheetsInWorker(sheetData);

    const mergeTiming = marky.stop("mergeFiles");
    (this as any).$gtm.push({
      event: "track-time",
      timing: mergeTiming.duration,
      category: "Generate Report",
      label: "Merge Data"
    });

    this.workflowStage = WORKFLOW.PREPARING_XLSX;
    marky.mark("xlsxProcessing");

    await mergeDataToXlsx(json, this.fileName || "gds-report");

    const xlsxTiming = marky.stop("xlsxProcessing");
    (this as any).$gtm.push({
      event: "track-time",
      timing: xlsxTiming.duration,
      category: "Generate Report",
      label: "Prepare XLSX"
    });

    this.workflowStage = WORKFLOW.COMPLETE;
    const totalTiming = marky.stop("processFiles");
    (this as any).$gtm.push({
      event: "process-files",
      timing: totalTiming.duration,
      category: "Generate Report",
      label: "Total time"
    });
  }
}
